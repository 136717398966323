import React, { useContext, useRef, useEffect } from "react";
import "./Page.scss";
import Utils from 'Utils/Utils';
import {ReactComponent as Printerest} from 'assets/pr.svg';
import {ReactComponent as Instagram} from 'assets/ig.svg';
import {ReactComponent as Youtube} from 'assets/yt.svg';
import {ReactComponent as Facebook} from 'assets/fb.svg';
import { SettingsContext } from "Utils/SettingsProvider";

function Page({content,social}) {
  const Container=useRef();
  const Spacer=useRef();
  const Text=useRef();
  const { modele } = useContext(SettingsContext);
  const urlPrinterest= modele.Principal && modele.Principal.Url_printerest;
  const urlTwitter= modele.Principal && modele.Principal.Url_twitter;
  const urlYoutube= modele.Principal && modele.Principal.Url_youtube;
  const urlFacebook= modele.Principal && modele.Principal.Url_facebook;
  useEffect(()=>{
    const handleResize=()=>{
      if (Spacer.current && Text.current && Container.current) {
        Spacer.current.style.height=Math.max(0,((Container.current.clientHeight-Text.current.clientHeight)/2.2))+'px';
      }
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>window.removeEventListener('resize',handleResize);
  },[Text,Spacer,Container,content])
  return <div className="page-inner" ref={Container}>
    <div ref={Spacer}></div>
    <div className="text" ref={Text}>
      {content && Utils.parseText(content)}
      {social && <div className="page-social">
        <a href={urlFacebook}><Facebook/></a>
        <span className="inter"/>
        <a href={urlTwitter}><Instagram/></a>
        <span className="inter"/>
        <a href={urlYoutube}><Youtube/></a>
        <span className="inter"/>
        <a href={urlPrinterest}><Printerest/></a>
      </div>}
    </div>
  </div>;
}

export default Page;
