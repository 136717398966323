import React, { useContext, useState } from "react";
import "./Player.scss";
import Vimeo from '@u-wave/react-vimeo';
import { SettingsContext } from "Utils/SettingsProvider";
import {ReactComponent as AudioOn} from 'assets/audio_on.svg';
import {ReactComponent as AudioOff} from 'assets/audio_off.svg';
import {ReactComponent as Play} from 'assets/play.svg';
import {ReactComponent as Pause} from 'assets/pause.svg';
import Utils from 'Utils/Utils';

function Player() {
  const { modele, lang } = useContext(SettingsContext);
  const [ volume, setVolume ] = useState(0);
  const [ paused, setPaused ] = useState(false);
  const [ currentTime, setCurrentTime ] = useState(false);
  const handleTimeUpdate=(player)=>{
    setCurrentTime(player.seconds);
  }
  const subtitle=modele.Home.['st_'+lang] ? modele.Home.['st_'+lang].find((o)=>o.data.start/1000<=currentTime && o.data.end/1000>=currentTime) : null;
  return <div className="player">
    <div className="player-container">
      {modele.Home && <Vimeo
        video={Utils.translate(modele.Home,'videoUrl',lang)}
        autoplay
        muted={true}
        volume={volume}
        responsive
        controls={false}
        loop={true}
        paused={paused}
        onTimeUpdate={handleTimeUpdate}
      />}
      <div className='subtitles-container'>
        {subtitle && <div className='subtitles-inner'>{subtitle.data.text}</div>}
      </div>
    </div>
    <div className={`play-pause${paused ? ' paused' : ''}`}>
      <Play className="play" onClick={()=>setPaused(false)}/>
      <Pause className="pause" onClick={()=>setPaused(true)}/>
    </div>
    <div className={`audio${volume===0 ? ' show' : ''}`}>
      {volume>0 && <AudioOn onClick={()=>setVolume(0)}/>}
      {volume===0 && <AudioOff onClick={()=>setVolume(1)}/>}
    </div>
  </div>;
}

export default Player;
