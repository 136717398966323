import React, { useContext } from "react";
import "./Titre.scss";
import {ReactComponent as TitreSVG} from 'assets/titre.svg';
import {ReactComponent as TitreMobileSVG} from 'assets/titre_mobile.svg';
import {ReactComponent as TitreSVGEN} from 'assets/titre_en.svg';
import {ReactComponent as TitreMobileSVGEN} from 'assets/titre_mobile_en.svg';
import {ReactComponent as TitreSVGDE} from 'assets/titre_de.svg';
import {ReactComponent as TitreMobileSVGDE} from 'assets/titre_mobile_de.svg';
import { SettingsContext } from "Utils/SettingsProvider";
import Hidden from '@material-ui/core/Hidden';

function Titre() {
  const { lang } = useContext(SettingsContext);
  return <div className="titre">
    <Hidden smDown>
      {lang==='fr' && <TitreSVG/>}
      {lang==='en' && <TitreSVGEN/>}
      {lang==='de' && <TitreSVGDE/>}
    </Hidden>
    <Hidden mdUp>
      {lang==='fr' && <TitreMobileSVG/>}
      {lang==='en' && <TitreMobileSVGEN/>}
      {lang==='de' && <TitreMobileSVGDE/>}
    </Hidden>
  </div>;
}

export default Titre;
