import React, { useContext,useState } from "react";
import "./Footer.scss";
import {Grid} from "@material-ui/core";
import {ReactComponent as Printerest} from 'assets/pr.svg';
import {ReactComponent as Instagram} from 'assets/ig.svg';
import {ReactComponent as Youtube} from 'assets/yt.svg';
import {ReactComponent as Facebook} from 'assets/fb.svg';
import {ReactComponent as Balulalo} from 'assets/balulalo.svg';
import {ReactComponent as Bachibouzouk} from 'assets/bachibouzouk.svg';
import {ReactComponent as FilmsIci} from 'assets/films-ici.svg';
import { SettingsContext } from "Utils/SettingsProvider";
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import Utils from 'Utils/Utils';
import CloseIcon from '@material-ui/icons/Close';

function Footer() {
  const { modele, setLang, langs, lang } = useContext(SettingsContext);
  const urlPrinterest= modele.Principal && modele.Principal.Url_printerest;
  const urlTwitter= modele.Principal && modele.Principal.Url_twitter;
  const urlYoutube= modele.Principal && modele.Principal.Url_youtube;
  const urlFacebook= modele.Principal && modele.Principal.Url_facebook;
  const urlBalulalo= modele.Principal && modele.Principal.Url_balulalo;
  const urlBachibouzouk= modele.Principal && modele.Principal.Url_bachibouzouk;
  const urlFilmsIci= modele.Principal && modele.Principal.Url_films_ici;
  const titreMentions= modele.MentionLegales && Utils.translate(modele.MentionLegales,'titre',lang);
  const titreCGU= modele.CGU && Utils.translate(modele.CGU,'titre',lang);
  const texteMentions= modele.MentionLegales && Utils.translate(modele.MentionLegales,'texte',lang);
  const texteCGU= modele.CGU && Utils.translate(modele.CGU,'texte',lang);
  const [dialog,setDialog]=useState(null);
  return <>
  <div className="footer">
  <Grid container spacing={3} style={{marginTop:0,marginBottom:0,}}>
    <Hidden mdDown>
    <Grid item xs={6} lg={4} className="social">
      <a href={urlFacebook}><Facebook/></a>
      <a href={urlTwitter}><Instagram/></a>
      <a href={urlYoutube}><Youtube/></a>
      <a href={urlPrinterest}><Printerest/></a>
    </Grid>
    <Grid item xs={6} lg={4} className="partenaires">
      {urlBalulalo!=='' ? <a href={urlBalulalo}><Balulalo/></a> : <span className="nolink"><Balulalo/></span>}
      {urlBachibouzouk!=='' ? <a href={urlBachibouzouk}><Bachibouzouk/></a> : <span className="nolink"><Bachibouzouk/></span>}
      {urlFilmsIci!=='' ? <a href={urlFilmsIci}><FilmsIci/></a> : <span className="nolink"><FilmsIci/></span>}
    </Grid>
    </Hidden>
    <Grid item xs={12} lg={4} className="infos">
      <span onClick={()=>setDialog('mentions')}>{titreMentions}</span>
      <span onClick={()=>setDialog('cgu')}>{titreCGU}</span>
    </Grid>
  </Grid>
  <Dialog className="popup mentions" open={dialog==='mentions'} onClose={()=>setDialog(null)}>
    <div className="popup-inner">
    {texteMentions && Utils.parseText(texteMentions)}
    </div>
    <div className="close" onClick={()=>setDialog(null)}><CloseIcon/></div>
  </Dialog>
  <Dialog className="popup cgu" open={dialog==='cgu'} onClose={()=>setDialog(null)}>
    <div className="popup-inner">
    {texteCGU && Utils.parseText(texteCGU)}
    </div>
    <div className="close" onClick={()=>setDialog(null)}><CloseIcon/></div>
  </Dialog>
  </div>
  <div className="lang-selector">{langs.map((l)=><span className={lang===l ? 'on' : ''} onClick={()=>setLang(l)}>{l}</span>)}</div>
  </>;
}

export default Footer;
