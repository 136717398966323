import React, { useContext } from "react";
import "./Indicator.scss";
import {ReactComponent as Puce} from 'assets/puce_off.svg';
import {ReactComponent as On} from 'assets/puce_on.svg';
import { SettingsContext } from "Utils/SettingsProvider";
import Utils from 'Utils/Utils';

function Indicator({step}) {
  const classes1="indic" + (step===0 ? ' on' : ' off' );
  const classes2="indic" + (step===1 ? ' on' : ' off' );
  const classes3="indic" + (step===2? ' on' : ' off' );
  const { modele, lang } = useContext(SettingsContext);
  return <div className="indicator">
    <div className="visuel">
      <div className={classes1}><Puce/><On className="active"/></div>
      <div className={classes2}><Puce/><On className="active"/></div>
      <div className={classes3}><Puce/><On className="active"/></div>
    </div>
    <div className={classes1} onClick={()=>document.getElementById('main-scroll').scroll(0,0)}>{modele.Principal && Utils.parseText(Utils.translate(modele.Principal,'bande_annonce',lang))}</div>
    <div className={classes2} onClick={()=>document.getElementById('main-scroll').scroll(0,window.innerHeight)}>{modele.Principal && Utils.parseText(Utils.translate(modele.Principal,'le_film',lang))}</div>
    <div className={classes3} onClick={()=>document.getElementById('main-scroll').scroll(0,window.innerHeight*2)}>{modele.Principal && Utils.parseText(Utils.translate(modele.Principal,'l_avant_premiere',lang))}</div>
  </div>;
}

export default Indicator;
