import React from 'react';
import Background from "components/Background";
import SettingsProvider from "Utils/SettingsProvider";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <SettingsProvider>
          <Background/>
        </SettingsProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
