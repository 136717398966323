import React, { useContext,useEffect,useState,useRef } from "react";
import "./Countdown.scss";
import { SettingsContext } from "Utils/SettingsProvider";
import { DateTime } from "luxon";
import Utils from 'Utils/Utils';

function Countdown() {
  const { modele, setPeriode, periode, loaded, lang } = useContext(SettingsContext);
  const [ countdown,setCountdown ] = useState(null);
  const localPeriode=useRef(-1);
  useEffect(()=>{
    if (modele.Principal) {
      const {date,time,date2,time2}=modele.Principal;
      const handleCountDown=()=>{
        const now=DateTime.local().setZone("Europe/Paris");
        const premiereOpen=DateTime.fromSQL(`${date} ${time}`, { zone: "Europe/Paris" });
        const vod=DateTime.fromSQL(`${date2} ${time2}`, { zone: "Europe/Paris" });
        const {days, hours, minutes, seconds}=premiereOpen.diff(now,['months', 'days', 'hours', 'minutes', 'seconds']).toObject();
        if ( now < premiereOpen ) {
          setCountdown({days, hours, minutes, seconds});
          if (localPeriode!==0) {
            localPeriode.current=0;
            setPeriode(0);
          }
        }
        if (now>=premiereOpen && now<vod) {
          if (localPeriode!==1) {
            localPeriode.current=1;
            setPeriode(1);
          }
        }
        if (now>=vod) {
          clearInterval(interval);
          if (localPeriode!==2) {
            localPeriode.current=2;
            setPeriode(2);
          }
        }
      };
      const interval=setInterval(handleCountDown,1000);
      handleCountDown();
      return ()=>clearInterval(interval);
    }
  },[modele,setCountdown,setPeriode]);
  const avantPremiereDans= modele.Principal && Utils.translate(modele.Principal,'avant_premiere_dans',lang);
  const daysLegende= modele.Principal && Utils.translate(modele.Principal,'jours',lang);
  const hoursLegende= modele.Principal && Utils.translate(modele.Principal,'heures',lang);
  const minutesLegende= modele.Principal && Utils.translate(modele.Principal,'minutes',lang);
  const secondsLegende= modele.Principal && Utils.translate(modele.Principal,'secondes',lang);
  return loaded===1 && periode===0 ? <div className="countdown">
    <div className="bulles">
      <div className="bulle bulle-1"></div>
      <div className="bulle bulle-2"></div>
      <div className="bulle bulle-3"></div>
      <div className="bulle bulle-4"></div>
    </div>
    {avantPremiereDans && <div className="avant-premiere-dans">{avantPremiereDans}</div>}
    {countdown && <>
      <div className="decompte days">
        {countdown.days.toString().padStart(2, "0")}
        <div className="legende">{daysLegende}</div>
      </div>
      <span className='inter'/>
      <div className="decompte hours">
        {countdown.hours.toString().padStart(2, "0")}
        <div className="legende">{hoursLegende}</div>
      </div>
      <span className='inter'/>
      <div className="decompte minutes">
        {countdown.minutes.toString().padStart(2, "0")}
        <div className="legende">{minutesLegende}</div>
      </div>
      <span className='inter'/>
      <div className="decompte seconds">
        {Math.floor(countdown.seconds).toString().padStart(2, "0")}
        <div className="legende">{secondsLegende}</div>
      </div>
    </>}
  </div> : '';
}

export default Countdown;
