import parse from 'html-react-parser';
const nbsp=(text)=>{
  let res=text+'';
  res=res.replace(/[ ]+!/gm,"&nbsp;!");
  res=res.replace(/[ ]+\?/gm,"&nbsp;?");
  res=res.replace(/[ ]+:/gm,"&nbsp;:");
  res=res.replace(/[ ]+;/gm,"&nbsp;;");
  res=res.replace('+----',"<span class='trait-gauche'></span>");
  res=res.replace('----+',"<span class='trait-droite'></span>");
  res=res.replace(/__/gm,"&nbsp;");
  return res;
}
const parseText = (text)=>{
  if (text) {
    let res=nbsp(text);
    return parse(res);
  }
  return '';
}
const translate = (o,key,lang)=>{
  let trad=o[key] || '';
  if (o[key+'_'+lang]) trad=o[key+'_'+lang];
  return trad;
}
const utils={
    parseText,
    nbsp,
    translate,
}
export default utils;
