import React, { useEffect, useRef, useState, useContext } from "react";
import "./Cercle.scss";
import Page from 'components/Page';
import Player from 'components/Player';
import { SettingsContext } from "Utils/SettingsProvider";
import Utils from 'Utils/Utils';

function Cercle({onInit,steps}) {
  const { size, loaded, modele, periode, lang } = useContext(SettingsContext);
  const Main=useRef();
  const Inner=useRef();
  const Page1=useRef();
  const Page2=useRef();
  const Page3=useRef();
  const PageMobile21=useRef();
  const PageMobile22=useRef();
  const PageMobile31=useRef();
  const PageMobile32=useRef();
  const PageMobileSocial=useRef();
  const [ ratio, setRatio ] = useState(1);
  useEffect(()=>{
      if (size!=='xs' && size!=='sm') onInit([Page1,Page2,Page3]);
      else onInit([Page1,PageMobile21,PageMobile22,PageMobile31,PageMobile32,PageMobileSocial]);
    },[
    Page1,
    Page2,
    Page3,
    PageMobile21,
    PageMobile22,
    PageMobile31,
    PageMobile32,
    PageMobileSocial,
    onInit,
    size,
    loaded
  ]);
  useEffect(()=>{
    const handleResize=()=>{
      if (Main.current && Inner.current) {
        const ratio=Main.current.clientWidth/Inner.current.clientWidth
        setRatio(ratio);
      }
    };
    console.log('refresh');
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=> window.removeEventListener('resize',handleResize);
  },[Main,Inner,setRatio,loaded]);
  useEffect(()=>{
    const root = document.documentElement;
    root.style.setProperty('--ratio', 1/ratio || 1);
  },[ratio]);
  return loaded===1 ? <div className="cercle" ref={Main}>
    <div className='inner-circle' ref={Inner} style={{transform:`scale(${ratio})`}}>
      <div className='page page-1' ref={Page1}>
        <Player/>
      </div>
      {(size!=='xs' && size!=='sm') ? <>
        <div className='page page-2' ref={Page2}>
          <Page content={Utils.translate(modele.Page2,'text',lang)}/>
        </div>
        <div className='page page-3' ref={Page3}>
          <Page content={periode<2 ?  Utils.translate(modele.Page3,'text',lang) : Utils.translate(modele.Page3,'vod_text',lang)}/>
        </div>
      </>:<>
        <div className='page page-2' ref={PageMobile21}>
          <Page content={Utils.translate(modele.Page2,'mobile_text1',lang)}/>
        </div>
        <div className='page page-3' ref={PageMobile22}>
          <Page content={Utils.translate(modele.Page2,'mobile_text2',lang)}/>
        </div>
        <div className='page page-4' ref={PageMobile31}>
          <Page content={periode<2 ? Utils.translate(modele.Page3,'mobile_text1',lang) : Utils.translate(modele.Page3,'vod_mobile_text1',lang)}/>
        </div>
        <div className='page page-5' ref={PageMobile32}>
          <Page content={periode<2 ? Utils.translate(modele.Page3,'mobile_text2',lang) : Utils.translate(modele.Page3,'vod_mobile_text2',lang)}/>
        </div>
        <div className='page page-6' ref={PageMobileSocial}>
          <Page content={Utils.translate(modele.Social,'text',lang)} social/>
        </div>
      </>}
    </div>
  </div> : '';
}

export default Cercle;
