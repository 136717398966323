import React, { useState, createContext, useEffect } from "react";
import Conf from "Utils/Conf";
import CockpitSDK from "cockpit-sdk";
import Queue from "queue";
import axios from "axios";
import * as Subtitle from 'subtitle';
const cockpit = new CockpitSDK({
  host: Conf.apiUrl,
  accessToken: Conf.apiToken,
});

export const SettingsContext = createContext({});

const nb=8;

const SettingsProvider = ({children})=>{
  const [ modele,setModele ] = useState({});
  const [ size,setSize ] = useState({});
  const [ orientation,setOrientation ] = useState({});
  const [ loaded,setLoaded ] = useState(0);
  const [ periode,setPeriode ] = useState(-1);
  const [ lang,setLang ] = useState('fr');
  useEffect(()=>{
    const handleResize=()=>{
      const W=window.innerWidth;
      const H=window.innerHeight;
      let s='xxl';
      if (W<=1600) s='xl';
      if (W<=1200) s='lg';
      if (W<=992) s='md';
      if (W<=768) s='sm';
      if (W<=576) s='xs';
      setSize(s);
      let o='landscape';
      if (H>W) o='portrait';
      setOrientation(o);
      const headerHeight=Math.min(H*0.14,160);
      const footerHeight=Math.min(H*0.06,69);
      const outerScreenHeight=H-headerHeight-footerHeight;
      const outerScreenWidth=W;
      const outerScreenRatio=outerScreenHeight/outerScreenWidth;
      let screenWidth=Math.min(1500,outerScreenWidth);
      let screenHeight=outerScreenHeight;
      let screenLeft=0;
      let screenTop=headerHeight;
      if (W<=768) {
        if (outerScreenRatio>1.5) {
          screenHeight=outerScreenWidth*1.5;
        }
        screenTop=headerHeight+(outerScreenHeight-screenHeight)/2;
      }
      if (W>768 && W<=992) {
        if (outerScreenRatio<0.6) {
          screenWidth=outerScreenHeight/0.6;
        } else {
          screenHeight=screenWidth*0.6;
        }
        screenLeft=(outerScreenWidth-screenWidth)/2;
        screenTop=headerHeight+(outerScreenHeight-screenHeight)/2;
      }
      if (W>992) {
        if (outerScreenRatio<0.46) {
          screenWidth=Math.min(1500,outerScreenHeight/0.46);
          screenHeight=screenWidth*0.46;
        } else {
          screenHeight=screenWidth*0.46;
        }
        screenLeft=(outerScreenWidth-screenWidth)/2;
        screenTop=headerHeight+(outerScreenHeight-screenHeight)/2;
      }
      const root = document.documentElement;
      root.style.setProperty('--main-width', `${W}px`);
      root.style.setProperty('--main-height', `${H}px`);
      root.style.setProperty('--header-height', `${headerHeight}px`);
      root.style.setProperty('--footer-height', `${footerHeight}px`);
      root.style.setProperty('--screen-height', `${screenHeight}px`);
      root.style.setProperty('--screen-width', `${screenWidth}px`);
      root.style.setProperty('--screen-left', `${screenLeft}px`);
      root.style.setProperty('--screen-top', `${screenTop}px`);
    };
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=> window.removeEventListener('resize',handleResize);
  },[setSize,setOrientation]);
  useEffect(()=>{
    let q=Queue({concurrency:1});
    const getSingleton=(id,i,cb,n=0)=>{
      console.log('get',id);
      cockpit.singletonGet(id).then(data => {
        if(data){
          console.log('got',id);
          setModele((modele)=>{return {...modele, [id]:data}});
          setLoaded(i/nb);
          if (id==='Home') {
            let stq=Queue({concurrency:1});
            let stTracks=[{lang:'fr',url:data.Subtitles}];
            if (data.Subtitles_en) stTracks.push({lang:'en',url:data.Subtitles_en});
            if (data.Subtitles_de) stTracks.push({lang:'de',url:data.Subtitles_de});
            stTracks.forEach((track)=>{
              if (track.url) {
                stq.push((c)=>{
                  //console.log(track);
                  axios.get(Conf.apiUrl+'/'+track.url).then((response)=>{
                    //console.log(Subtitle.parseSync(response.data),'st_'+track.lang);
                    setModele((modele)=>{return {...modele, [id]:{...modele[id],['st_'+track.lang]:Subtitle.parseSync(response.data)}}});
                    c();
                  }).catch((err)=>{
                    console.log(err);
                    c();
                  });
                });
              }
            });
            stq.on('end', ()=> {
              console.log('subtitles fetched!');
              cb();
            });
            stq.start();
          } else {
            return cb();
          }
        }
      }).catch(error=>{
         console.log('error',id);
         if (n<5) getSingleton(id,i,cb,n+1);
      });
    };
    q.push((cb)=>
      getSingleton('Principal',1,cb)
    );
    q.push((cb)=>
      getSingleton('Home',2,cb)
    );
    q.push((cb)=>
      getSingleton('Page2',3,cb)
    );
    q.push((cb)=>
      getSingleton('Page3',4,cb)
    );
    q.push((cb)=>
      getSingleton('MentionLegales',5,cb)
    );
    q.push((cb)=>
      getSingleton('CGU',6,cb)
    );
    q.push((cb)=>
      getSingleton('Social',7,cb)
    );
    q.on('end', ()=> {
      console.log('done!');
      setLoaded(8/nb);
    });
    q.start();
  },[]);
  useEffect(()=>{
    let userLang=(navigator.language || navigator.userLanguage).substr(0,2).toLowerCase();
    let localLang = localStorage.getItem('userlang');
    if (localLang || userLang) setLang(localLang || userLang);
  },[setLang]);
  useEffect(()=>{
    localStorage.setItem('userlang',lang);
  },[lang]);
  return (
        <SettingsContext.Provider value={{modele,size,orientation,loaded,periode,setPeriode,lang,setLang,langs:Conf.langs}}>
            {modele && children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
