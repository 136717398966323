import React, {useEffect, useRef, useState, useContext} from "react";
import "./Background.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {ReactComponent as FondPoissons1} from 'assets/fond_1.svg';
import {ReactComponent as FondPoissons2} from 'assets/fond_2.svg';
import ReactSwipeEvents from 'react-swipe-events';
import Footer from 'components/Footer';
import Titre from 'components/Titre';
import Cercle from 'components/Cercle';
import Indicator from 'components/Indicator';
import Countdown from 'components/Countdown';
import MainButton from 'components/MainButton';
import { SettingsContext } from "Utils/SettingsProvider";
gsap.registerPlugin(ScrollTrigger);

function Background() {
  const [ pages,setPages ] = useState({});
  const [ step,setStep ] = useState(0);
  const Scroller=useRef();
  const Main=useRef();
  const Fond1=useRef();
  const Fond2=useRef();
  const { size, loaded } = useContext(SettingsContext);
  const next=()=>{
    if (size==='sm' || size==='xs') {
      const target=window.innerWidth*Math.min(pages.length,step+1);
      Scroller.current.scroll(target,0);
    }
  }
  const prev=()=>{
    if (size==='sm' || size==='xs') {
      const target=window.innerWidth*Math.max(0,step-1);
      Scroller.current.scroll(target,0);
    }
  }
  let style={};
  if (size==='xs' || size==='sm') style.width=`${pages.length*100}vw`;
  useEffect(()=>{
    if (Scroller.current && Main.current && Fond1.current && Fond2.current && pages[0]) {
      const bounds=(i,fond)=>{
        const start=i*(window.innerHeight - window.innerHeight*0.8 - fond.clientHeight)/(pages.length-1);
        const stop=(i+1)*(window.innerHeight - window.innerHeight*0.8 - fond.clientHeight)/(pages.length-1);
        return {start,stop};
      }
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: Main.current,
          scroller: Scroller.current,
          horizontal: size==='xs' || size==='sm',
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
          onUpdate:(self)=>{
            pages.forEach((p,i)=>{
              if (Math.round(self.progress*100)===100*i/(pages.length-1)) setStep(i)
            });
          },
        }
      });
      const increment=1/(pages.length-1);
      for(let i=pages.length-2;i>=0;i--){
          if (i>0) tl.fromTo(pages[i].current,{yPercent:0},{yPercent:-100, duration:0.75*increment},(i+0.25)*increment);
          tl.fromTo(pages[i].current,{opacity:1},{opacity:0, duration:0.75*increment},(i+0.25)*increment);
          tl.fromTo(pages[i+1].current,{opacity:0},{opacity:1, duration:0.75*increment},(i+0.25)*increment);
          tl.fromTo(pages[i+1].current,{yPercent:100},{yPercent:0, duration:0.75*increment},(i+0.25)*increment);
          tl.fromTo(Fond2.current,{y:()=>bounds(i,Fond2.current).start},{y:()=>bounds(i,Fond2.current).stop, duration:increment},i/(pages.length-1));
          tl.fromTo(Fond1.current,{y:()=>bounds(i,Fond1.current).start},{y:()=>bounds(i,Fond1.current).stop, duration:increment},i/(pages.length-1));
      }
      return ()=>{
        tl.kill();
      }
    }
  },[Scroller,Main,Fond1,Fond2,pages,setStep,size]);
  const content=<div className="foreground-container">
    <div className="foreground">
      <div className="layout-header">
        <Titre/>
      </div>
      <div className="layout-footer">
        <Footer/>
      </div>
      <div className="layout-screen">
        <Countdown/>
        <Cercle onInit={setPages} step={step}/>
        {(size!=='sm' && size!=='xs') && <Indicator step={step}/>}
        <MainButton/>
      </div>
    </div>
  </div>;
  return <>
  {loaded===1 && <>
  <div className="background">
    <FondPoissons2 className="fond2" ref={Fond2}/>
    <FondPoissons1 className="fond1" ref={Fond1}/>
  </div>
  <div className="scroll-container" ref={Scroller} id="main-scroll">
    <div className="scroll-inner" ref={Main} style={style}>
      {pages.length>0 && pages.map((p,i)=><div key={i} className={`panel panel-${i}`}></div>)}
    </div>
    {(size!=='sm' && size!=='xs') ? content : ''}
  </div>
  {(size==='sm' || size==='xs') && <ReactSwipeEvents onSwipedLeft={next} onSwipedRight={prev}>
    {content}
  </ReactSwipeEvents>}
  </>}
  <div className={`loader${loaded===1 ? ' done':''}`} style={{width:`${loaded*100}%`}}></div>
  </>;
}

export default Background;
