import React, { useContext } from "react";
import "./MainButton.scss";
import {ReactComponent as Oeil} from 'assets/oeil_ok.svg';
import {ReactComponent as OeilHover} from 'assets/oeil_hover_ok.svg';
import {ReactComponent as OeilActive} from 'assets/oeil_active_ok.svg';
import {ReactComponent as BtnMobile} from 'assets/btn_mobile.svg';
import { SettingsContext } from "Utils/SettingsProvider";
import Utils from 'Utils/Utils';

function MainButton() {
  const { modele, periode, size, lang } = useContext(SettingsContext);
  let url='';
  if (periode===0) url = modele.Principal ? modele.Principal.Url_beem : '';
  if (periode===1) url = modele.Principal ? modele.Principal.Url_beem : '';
  if (periode===2) url = modele.Principal ? Utils.translate(modele.Principal,'Url_vimeo',lang) : '';
  return url!=='' ? <div className="main-button">
    {(size!=='sm' && size!=='xs') ?
    <a href={url} target="_blank" onClick={()=>window.plausible('Click',{props:{url}})} rel="noreferrer">
      <OeilActive className="oeil-active"/>
      <OeilHover className="oeil-hover"/>
      <Oeil className="oeil"/>
      <div className="reserver">
        <div>
          {modele.Principal && periode===0 && Utils.parseText(Utils.translate(modele.Principal,'reserver',lang))}
          {modele.Principal && periode===1 && Utils.parseText(Utils.translate(modele.Principal,'acceder',lang))}
          {modele.Principal && periode===2 && Utils.parseText(Utils.translate(modele.Principal,'acceder_vod',lang))}
        </div>
      </div>
    </a>
    :
    <a href={url} target="_blank" rel="noreferrer">
      <BtnMobile className="oeil-mobile"/>
      <div className="reserver-mobile">
        {modele.Principal && periode===0 && Utils.parseText(Utils.translate(modele.Principal,'reserver',lang))}
        {modele.Principal && periode===1 && Utils.parseText(Utils.translate(modele.Principal,'acceder',lang))}
        {modele.Principal && periode===2 && Utils.parseText(Utils.translate(modele.Principal,'acceder_vod',lang))}
      </div>
    </a>}
  </div> : '';
}

export default MainButton;
